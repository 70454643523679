<template>
  <v-card flat class="my-6 mx-9 rounded-lg" color="#f5f7f7">
    <div class="card-wrapper">
      <div class="d-flex" v-for="(item, index) in details.items" :key="index">
        <div class="left text-overline grey--text">
          {{ item.text }}
        </div>

        <div
          class="right text-overline text-left"
          :class="{ 'font-weight-black': item.bold }"
        >
          {{ item.value }}
        </div>
      </div>
    </div>

    <v-divider class="my-4"></v-divider>

    <div class="ml-2">
      <v-btn icon>
        <v-icon color="black">mdi-link</v-icon>
      </v-btn>

      <v-btn small text color="blue">{{ details.footer }}</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["details"],
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  min-height: 130px;
  padding-top: 20px;
}

.left {
  padding-left: 20px;
}

.right {
  padding-left: 20px;
  width: 85%;
}
</style>